import React, { useEffect } from 'react';

import gsap from 'gsap';

import Cursor from '../components/Cursor';
import SeoBasic from '../components/SeoBasic';
import Colophon from '../components/Colophon';
import Header from '../components/Header';
// import Scroll from '../components/Scroll';

const Sviluppoapp = ({ transitionStatus }) => {
  useEffect(() => {
    if (transitionStatus === 'entering') {
      gsap.fromTo(
        '#sviluppo-app',
        { opacity: '0' },
        { delay: 0, duration: 0.1, opacity: '1' }
      );

      gsap.fromTo(
        '#sviluppo-app',
        { x: '-100' },
        { delay: 0, duration: 0.8, x: 0, ease: 'back' }
      );
    }
    if (transitionStatus === 'exiting') {
      gsap.to('#sviluppo-app', { delay: 0.1, duration: 0.8, opacity: 0 });
      gsap.to('#lower', { delay: 0, duration: 0.6, y: 110, opacity: 0 });
    }
  }, [transitionStatus]);
  return (
    <main className="container mx-auto px-2 md:px-0">
      <SeoBasic slug="home" />
      <Cursor />
      <Header />
      <div
        className="w-full h-full flex justify-center items-center  opacity-0"
        id="sviluppo-app"
      >
        <p className="list-none text-4xl md:text-6xl lg:text-8xl font-bold relative overflow-hidden uppercase transform ">
          Sviluppo APP
        </p>
      </div>
      <div className="flex-col" id="lower">
        <div className="text-center w-2/4  m-auto mb-16">
          <div className="uppercase text-xl">SVILUPPO APP NATIVE</div>
          Dall’analisi del progetto allo sviluppo dell’interfaccia e delle
          funzionalità di una APP nativa per iOS e Android
        </div>
        <div className="text-center w-2/4  m-auto mb-16">
          <div className="uppercase text-xl">SVILUPPO PROGRESSIVE WEB APP</div>
          Sviluppo di Web App fruibili da mobile anche in modalità offline
        </div>
        <div className="text-center w-2/4  m-auto mb-16">
          <div className="uppercase text-xl">INTEGRAZIONE BACKEND</div>App
          costruite intorno ad una base dati o contenuti già esistenti
        </div>
        <div className="text-center w-2/4  m-auto mb-16">
          <div className="uppercase text-xl">SVILUPPO BACKEND</div>
          Implementazioni di backend, basi dati, sistemi di autenticazione su
          piattaforma Google Firebase
        </div>
        <div className="text-center w-2/4  m-auto mb-16">
          <div className="uppercase text-xl">PUBBLICAZIONE</div>Testing e
          pubblicazione delle App native sui rispettivi store
        </div>
      </div>
      <Colophon />
    </main>
  );
};

export default Sviluppoapp;
